<template>
  <div id="email-seeds__table">
    <!------------------------- Table  starts------------------------->
    <v-data-table
      :items="emailSeeds"
      :headers="headers"
      :loading="isLoading"
      class="elevation-1 body-2"
      :item-class="rowClass"
      disable-pagination
      hide-default-footer
      @current-items="onCurrentItems"
      :loading-text="$dictionary.app.common.seeds.loading"
      :no-data-text="$dictionary.app.seeds.index.noResults"
      :sort-desc.sync="isSortDesc"
      :sort-by="settings.sort_by"
      @update:options="updateTableSettings"
      @click:row="openDrawer"
    >
      <template #item.seed_address="{ item }">
        <table-matrics-row :text="item.seed_address" />
      </template>
      <template #item.received_at="{ item }">
        <table-matrics-row :text="item.received_at" />
      </template>
      <template #item.sending_ip="{ item }">
        <table-matrics-row :text="item.sending_ip" />
      </template>
      <template #item.mailbox_type="{ item }">
        <table-matrics-row :text="item.mailbox_type" />
      </template>
      <template #item.mailbox_label="{ item }">
        <table-matrics-row :text="item.mailbox_label" />
      </template>
    </v-data-table>
    <!------------------------- Table ends ------------------------->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { SORTING_ORDERS } from "@/constants";
import { TableMatricsRow } from "../shared";

export default {
  name: "EmailSeedsTable",
  /**
   *  ---------------- Components ------------------
   */
  components: {
    TableMatricsRow,
  },
  /**
   *  ---------------- Props ------------------
   */
  props: {
    emailSeeds: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isDrawerOpen: {
      type: Boolean,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  /**
   * ---------------- Data properties ------------------
   */
  data() {
    return {
      selectedSeedAddress: "",
      pagination: {},
    };
  },
  /**
   * ---------------- Custom events ------------------
   */
  emits: ["open-drawer", "get-email-source", "current-items"],
  /**
   * ---------------- Methods ------------------
   */
  computed: {
    ...mapGetters({
      settings: "settings/emailseeds",
    }),
    /**
     * Determines if sorting is in descending order
     * @type {Boolean}
     */
    isSortDesc() {
      return this.settings.sort_order === SORTING_ORDERS.desc;
    },
  },
  methods: {
    ...mapActions({
      updateSettings: "settings/setEmailSeedsSettings",
    }),
    /**
     * rowClass
     * Classes of a row
     */
    rowClass(seed) {
      return !this.isSeedMissing(seed) ? "cursor-pointer" : "";
    },
    /**
     * updateTableSettings
     * @param {Object} options Current settings of the table
     * Updates the table settings
     */
    updateTableSettings(options) {
      let [sort_by] = options.sortBy;
      let [sort_order] = options.sortDesc;

      let table = {
        sort_order: sort_order ? SORTING_ORDERS.desc : SORTING_ORDERS.asc,
        sort_by: sort_by ?? "seed_address",
      };

      this.updateSettings(table);
    },
    /**
     * isSeedMissing
     * @returns {Boolean} Determines whether a seed is missing or not
     */
    isSeedMissing({ mailbox_type }) {
      const MISSING_STATUS = "missing";
      return mailbox_type?.toLowerCase() === MISSING_STATUS;
    },
    /**
     * Open seed drawer on selecting seed
     * @param {Object} item Seed details
     */
    openDrawer({ seed_address, mailbox_type, id }) {
      if (!this.isSeedMissing({ mailbox_type })) {
        !this.isDrawerOpen && this.$emit("open-drawer");
        seed_address && this.$emit("get-email-source", { seed_address, id });
        // On open drawer, Scroll to top of the page
        window.scroll(0, 0);
      }
    },

    /**
     * Emit current sorted list to parent
     * @param {Array} value List of email seeds
     */
    onCurrentItems(value) {
      this.$emit("current-items", value);
    },
  },
};
</script>
