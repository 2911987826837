<template>
  <div id="camapign-table">
    <!------------------------- Table  starts------------------------->
    <v-data-table
      :items="accountCampaigns"
      :headers="headers"
      :loading="isLoading"
      :loading-text="$dictionary.app.common.campaigns.loading"
      :class="{ 'elevation-1': !isDashboard }"
      class="body-1"
      :options.sync="pagination"
      :hide-default-footer="hideDefaultFooter"
      :items-per-page="paginationMeta.itemsPerPage"
      :server-items-length="serverItemsLength"
      :no-data-text="$dictionary.app.accountCampaigns.index.noResults"
      :sort-by="tableSettings.sort_by"
      :sort-desc.sync="isSortDesc"
      @update:items-per-page="updateItemsPerPage"
      :footer-props="{
        itemsPerPageOptions: tableSettings.itemsPerPageOptions,
        itemsPerPage: tableSettings.itemsPerPage,
      }"
      :height="height"
      :disable-pagination="disablePagination"
      @update:options="updateTable"
      :item-class="rowClass"
      @click:row="addMailboxNavigator"
    >
      <template #item.subject="{ item }">
        <text-ellipsis
          :text="item.subject"
          @clicked="mailboxNavigator(item)"
          class="cursor-pointer body-2 text--primary text-black--80"
        />
      </template>
      <template #item.inbox_count="{ item }">
        <table-matrics-row
          :count="item.inbox_count"
          :percent="item.inbox"
          :threshold-type="$appConfig.thresholds.inbox"
        />
      </template>
      <template #item.id="{ item }">
        <table-matrics-row :text="item.id" />
      </template>
      <template #item.spam_count="{ item }">
        <table-matrics-row
          :count="item.spam_count"
          :percent="item.spam"
          :threshold-type="$appConfig.thresholds.spam"
        />
      </template>
      <template #item.missing_count="{ item }">
        <table-matrics-row
          :count="item.missing_count"
          :percent="item.missing"
          :threshold-type="$appConfig.thresholds.missing"
        />
      </template>
      <template #item.first_seen_date="{ item }">
        <table-matrics-row :text="item.first_seen" />
      </template>
      <template #item.first_seen="{ item }">
        <table-matrics-row :text="item.first_seen" />
      </template>
      <template #item.from_domain="{ item }">
        <table-matrics-row :text="item.from_domain" />
      </template>
      <template #item.durationMillis="{ item }">
        <table-matrics-row :text="item.duration" />
      </template>
      <template #item.duration="{ item }">
        <table-matrics-row :text="item.duration" />
      </template>
    </v-data-table>
    <!------------------------- Table ends ------------------------->
  </div>
</template>

<script>
import { TableMatricsRow, TextEllipsis } from "../shared";
import { isType } from "@/utils/common.utils";
import { campaignHeaders } from "@/data/table-headers";
import { SORTING_ORDERS, DEFAULT_ITEMS_PER_PAGE } from "@/constants";

export default {
  name: "CampaignsTable",
  /**
   *  ---------------- Components ------------------
   */
  components: {
    TableMatricsRow,
    TextEllipsis,
  },
  props: {
    /**
     * List of camapigns of the account
     */
    accountCampaigns: {
      type: Array,
      required: true,
    },
    headers: { type: Array, default: () => campaignHeaders },
    isLoading: {
      type: Boolean,
      required: true,
    },
    campaignAccount: {
      type: Object,
    },

    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    maxRowDisplay: {
      type: Number,
    },
    isClientSideSort: {
      type: Boolean,
      default: false,
    },
    tableSettings: {
      type: Object,
      required: true,
    },
    updateSettings: {
      type: Function,
      required: true,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
    },
  },
  /**
   * ---------------- Custom events ------------------
   */
  emits: ["get-campaigns", "navigate-to-mailbox"],
  /**
   * ---------------- Data properties ------------------
   */
  data() {
    return {
      pagination: {
        ...this.tableSettings,
        sortDesc: [
          this.tableSettings.sort_order === SORTING_ORDERS.desc ? true : null,
        ],
      },
    };
  },

  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    /**
     * Campaign listing pagination meta
     * @type {Object}
     */
    paginationMeta() {
      return {
        itemsPerPage: this.pagination.itemsPerPage || this.maxRowDisplay || 10,
        serverItemsLength:
          this.pagination.page * this.accountCampaigns?.length +
          this.pagination.itemsPerPage,
      };
    },

    /**
     * Set row class of data table
     */
    rowClass() {
      return () => (this.isDashboard ? "cursor-pointer" : "");
    },

    /**
     * Request params to be sent to the account campaigns api
     * to fetch the campaigns of an account on the basis of the params
     */
    params() {
      let { sort_by, sort_order } = this.tableSettings;

      sort_by = `${sort_by} ${sort_order}`;
      return {
        ...this.campaignAccount,
        page: this.pagination.page || DEFAULT_ITEMS_PER_PAGE,
        per_page:
          this.maxRowDisplay ||
          this.pagination.itemsPerPage ||
          this.paginationMeta.itemsPerPage,
        "q[s]": sort_by,
      };
    },
    /**
     * Checks if the page is dashboard
     * @type {Boolean}
     */
    isDashboard() {
      return this.$route?.path === this.$paths?.dashboard;
    },
    /**
     * Adds mailboxNavigator method if the current page is dashboard
     */
    addMailboxNavigator() {
      return this.isDashboard ? this.mailboxNavigator : () => {};
    },
    /**
     * set serverItemsLength for sorting
     */
    serverItemsLength() {
      return this.isClientSideSort ? -1 : this.paginationMeta.serverItemsLength;
    },
    /**
     * Computes if the sort order is desc
     * @type {Boolean}
     */
    isSortDesc: {
      get() {
        return this.tableSettings.sort_order === SORTING_ORDERS.desc;
      },
      set(val) {
        return val;
      },
    },
  },
  /**
   * ---------------- Methods ------------------
   */
  methods: {
    updateItemsPerPage(val) {
      this.updateSettings({ itemsPerPage: val });
    },
    /**
     * mailboxNavigator
     * @emits navigate-to-mailbox event in the parent component to navigate to mailbox providors page
     */
    mailboxNavigator({ id, ...account }) {
      if (!id) return;

      this.$emit("navigate-to-mailbox", {
        ...account,
        campaign_id: id,
      });
    },

    async updateTable() {
      await this.updateTableSettings();
      if (!this.isClientSideSort) {
        this.getCampaigns(this.params);
      }
      return this.isClientSideSort;
    },
    updateTableSettings() {
      let [sort_by] = this.pagination.sortBy;
      let [sort_order] = this.pagination.sortDesc;
      const isOrderDesc = sort_order || isType(sort_order, "undefined");

      let table = {
        sort_order: isOrderDesc ? SORTING_ORDERS.desc : SORTING_ORDERS.asc,
        sort_by:
          sort_by ??
          (!this.isClientSideSort ? "first_seen" : "first_seen_date"),
      };

      this.updateSettings(table);
    },
    /**
     * Emits event in the parent component to fetch the campaigns
     * @emits get-campaigns
     */
    getCampaigns(params) {
      let paramsObj = params;

      /** Removes sort_order and sort_by properties from the params if current route is dashboard */
      if (this.isDashboard) {
        paramsObj = Object.assign({}, params);
        delete paramsObj.sort_order;
        delete paramsObj.sort_by;
      }
      this.$emit("get-campaigns", paramsObj);
    },

    /**
     * Reset page count
     */
    resetPageCount() {
      this.pagination.page = 1;
    },
  },
};
</script>
