var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"camapign-table"}},[_c('v-data-table',{staticClass:"body-1",class:{ 'elevation-1': !_vm.isDashboard },attrs:{"items":_vm.accountCampaigns,"headers":_vm.headers,"loading":_vm.isLoading,"loading-text":_vm.$dictionary.app.common.campaigns.loading,"options":_vm.pagination,"hide-default-footer":_vm.hideDefaultFooter,"items-per-page":_vm.paginationMeta.itemsPerPage,"server-items-length":_vm.serverItemsLength,"no-data-text":_vm.$dictionary.app.accountCampaigns.index.noResults,"sort-by":_vm.tableSettings.sort_by,"sort-desc":_vm.isSortDesc,"footer-props":{
      itemsPerPageOptions: _vm.tableSettings.itemsPerPageOptions,
      itemsPerPage: _vm.tableSettings.itemsPerPage,
    },"height":_vm.height,"disable-pagination":_vm.disablePagination,"item-class":_vm.rowClass},on:{"update:options":[function($event){_vm.pagination=$event},_vm.updateTable],"update:sortDesc":function($event){_vm.isSortDesc=$event},"update:sort-desc":function($event){_vm.isSortDesc=$event},"update:items-per-page":_vm.updateItemsPerPage,"click:row":_vm.addMailboxNavigator},scopedSlots:_vm._u([{key:"item.subject",fn:function(ref){
    var item = ref.item;
return [_c('text-ellipsis',{staticClass:"cursor-pointer body-2 text--primary text-black--80",attrs:{"text":item.subject},on:{"clicked":function($event){return _vm.mailboxNavigator(item)}}})]}},{key:"item.inbox_count",fn:function(ref){
    var item = ref.item;
return [_c('table-matrics-row',{attrs:{"count":item.inbox_count,"percent":item.inbox,"threshold-type":_vm.$appConfig.thresholds.inbox}})]}},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('table-matrics-row',{attrs:{"text":item.id}})]}},{key:"item.spam_count",fn:function(ref){
    var item = ref.item;
return [_c('table-matrics-row',{attrs:{"count":item.spam_count,"percent":item.spam,"threshold-type":_vm.$appConfig.thresholds.spam}})]}},{key:"item.missing_count",fn:function(ref){
    var item = ref.item;
return [_c('table-matrics-row',{attrs:{"count":item.missing_count,"percent":item.missing,"threshold-type":_vm.$appConfig.thresholds.missing}})]}},{key:"item.first_seen_date",fn:function(ref){
    var item = ref.item;
return [_c('table-matrics-row',{attrs:{"text":item.first_seen}})]}},{key:"item.first_seen",fn:function(ref){
    var item = ref.item;
return [_c('table-matrics-row',{attrs:{"text":item.first_seen}})]}},{key:"item.from_domain",fn:function(ref){
    var item = ref.item;
return [_c('table-matrics-row',{attrs:{"text":item.from_domain}})]}},{key:"item.durationMillis",fn:function(ref){
    var item = ref.item;
return [_c('table-matrics-row',{attrs:{"text":item.duration}})]}},{key:"item.duration",fn:function(ref){
    var item = ref.item;
return [_c('table-matrics-row',{attrs:{"text":item.duration}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }