<template>
  <div id="users-table">
    <!------------------------- Table  starts------------------------->
    <v-data-table
      :items="data"
      :headers="headers"
      :loading="isLoading"
      :header-props="{ class: 'subtitle-2' }"
      :options.sync="pagination"
      :items-per-page="tableSettings.itemsPerPage"
      :server-items-length="paginationMeta.serverItemsLength"
      :no-data-text="$dictionary.app.users.index.noResults"
      :loading-text="$dictionary.app.common.users.loading"
      @update:options="updateTableSettings"
      @update:items-per-page="updateItemsPerPage"
      :footer-props="footerProps"
    >
      <template #item.name="{ item }">
        <table-matrics-row :text="firstName(item)" />
      </template>
      <template #item.email="{ item }">
        <table-matrics-row :text="item.email" />
      </template>

      <template #item.role="{ item }">
        <table-matrics-row :text="capitalize(item.role)" />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu left bottom offset-y v-if="isAdmin">
          <template #activator="{ on, attrs }">
            <v-btn icon @click.stop="accessDeniedNotification" v-on="on">
              <v-icon v-bind="attrs" class="text--secondary">
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item @click="editUser(item)">
              <v-icon class="mr-3 headline text--secondary">
                mdi-pencil
              </v-icon>
              <v-list-item-title class="primary--font body-2 pr-3">
                Edit Permission
              </v-list-item-title>
            </v-list-item>

            <v-list-item @click="removeUserConfirmation(item)">
              <v-icon class="mr-3 headline text--secondary">
                mdi-delete
              </v-icon>
              <v-list-item-title class="primary--font body-2 pr-3">
                Delete User
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div v-else class="action-btn d-flex justify-center">
          <v-btn icon @click.stop="accessDeniedNotification">
            <v-icon class="text--secondary">mdi-dots-vertical</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <!------------------------- Table ends ------------------------->

    <dialog-box
      :dialog="confirmation.dialog"
      @close="confirmation.dialog = $event"
      width="500px"
    >
      <v-card class="pa-8 pb-5">
        <v-card-title class="headline pa-0 pb-4">
          <h6 class="text-h6 secondary-font text--primary font-weight-large ">
            {{ confirmation.title }}
          </h6>
        </v-card-title>
        <v-card-text class="pa-0 body-2 text--secondary">
          {{ confirmation.description }}
        </v-card-text>
        <br />
        <v-card-actions justify-end class="pa-0">
          <v-spacer />

          <v-btn
            text
            @click.stop="confirmation.dialog = false"
            class="text--primary font-weight-bold secondary--font"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            @click.stop="onConfirm(confirmation.item)"
            :loading="isUserDeleting"
            class="text--primary font-weight-bold secondary--font"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </dialog-box>
  </div>
</template>

<script>
import { capitalize } from "@/filters";
import { mapActions, mapGetters } from "vuex";
import { isResponseCode } from "@/utils/common.utils";

import { removeUserFromAccount } from "@/services";
import { TableMatricsRow, DialogBox } from "../shared";
import {
  RESPONSE_CODES,
  SORTING_ORDERS,
  TABLE_PER_PAGES_OPTIONS,
} from "@/constants";

import { userHeaders as headers } from "@/data/table-headers";

/**
 * Users table
 * @description shows listing of users in data table
 */
export default {
  name: "UsersTable",
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: {
    DialogBox,
    TableMatricsRow,
  },
  /**
  |--------------------------------------------------
  | Props
  |--------------------------------------------------
  */
  props: {
    /**
     * List of users of the account
     */
    data: { type: Array, required: true },
    isLoading: { type: Boolean, required: true },
    tableSettings: { type: Object, required: true },
    updateSettings: { type: Function, required: true },
    isAdmin: { type: Boolean, required: true },
  },
  /**
   * ---------------- Custom events ------------------
   */
  emits: ["get-users", "edit-user"],
  /**
   * ---------------- Data properties ------------------
   */
  data() {
    return {
      confirmation: {
        dialog: false,
        title: "",
        description: "",
      },
      user: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        role_name: "",
        password: "",
        confirmPassword: "",
      },
      headers,
      isUserDeleting: false,
      footerProps: {
        itemsPerPageOptions: [...TABLE_PER_PAGES_OPTIONS],
        itemsPerPage: TABLE_PER_PAGES_OPTIONS[3],
      },
      // Pagination property responsible for table pagination
      pagination: { sortDesc: [], sortBy: [] },
      sortParams: {
        email: `member_of_User_type_email`,
        name: `member_of_User_type_first_name`,
        role: `role`,
      },
    };
  },

  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    ...mapGetters({
      selectedAccount: "ui/selectedAccount",
    }),
    /**
     * Server side table pagination config
     * @description Computes server side data to make pagination work properly
     * @type {Object}
     */
    paginationMeta() {
      return {
        itemsPerPage: this.pagination.itemsPerPage || 10,
        serverItemsLength:
          this.pagination.page * this.data?.length +
          this.pagination.itemsPerPage,
      };
    },
    /**
     * Computes if the sort order is desc
     * @type {Boolean}
     */
    isSortDesc: {
      get() {
        return this.tableSettings.sort_order === SORTING_ORDERS.desc;
      },
    },
  },
  /**
   * ---------------- Methods ------------------
   */
  methods: {
    /**
     * Updates table latest settings in localstorage and persists
     */
    async updateTableSettings({ sortBy, sortDesc, itemsPerPage, page }) {
      [sortBy] = sortBy;
      [sortDesc] = sortDesc;

      const settings = {
        sort_by: sortBy ?? "email",
        itemsPerPage,
        sort_order: sortDesc ? SORTING_ORDERS.desc : SORTING_ORDERS.asc,
      };
      // Updates sorting params in local storage
      await this.updateSettings(settings);
      const params = { per_page: itemsPerPage, page };
      await this.getSortingParams(params);
      this.getRecords(params);
    },
    /**
     * Computes users request sorting params
     */
    getSortingParams(params) {
      const { sort_by, sort_order } = this.tableSettings;
      let sortBy = this.sortParams[sort_by];

      if (sortBy) sortBy = `${sortBy} ${sort_order}`;
      else sortBy = `${this.sortParams.email} ${sort_order}`;

      if (sort_by === "email") this.pagination.sortBy.push(sort_by);
      params["q[s]"] = sortBy;
    },
    capitalize,
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
    }),
    /**
     * @emits get-records event in the parent component to fetch user records listing
     */
    getRecords(settings) {
      this.$emit("get-records", settings);
    },
    /**
     * firstName
     * @returns First name of the user
     */
    firstName({ first_name, last_name }) {
      return `${first_name ?? ""} ${last_name ?? ""}`;
    },
    /**
     * Update items per page
     */
    updateItemsPerPage(val) {
      this.updateSettings({ itemsPerPage: val });
    },
    /**
     * Remove user confirmtion
     * @param {Object} item User details
     */
    removeUserConfirmation(item) {
      this.confirmation = {
        dialog: true,
        item: item,
        title: this.$dictionary.app.users.delete.title.replace(
          /<<name>>/gi,
          `'${item.first_name || item.email}'`
        ),
        description: this.$dictionary.app.users.delete.description.replace(
          /<<account>>/gi,
          this.selectedAccount?.account_name
        ),
      };
    },

    /**
     * When user confirmed removed activity
     */
    async onConfirm({ first_name, email, id }) {
      try {
        this.isUserDeleting = true;
        this.confirmation.dialog = false;

        const { status } = await removeUserFromAccount({
          id,
          account_id: this.selectedAccount?.account_id,
        });

        if (isResponseCode(RESPONSE_CODES.noContent, status)) {
          this.setSnackbar({
            value: true,
            message: this.$dictionary.app.users.deletedMsg.replace(
              /<<name>>/gi,
              first_name || email
            ),
            type: this.$appConfig.snackbar.snackbarTypes.success,
          });
        }

        this.getRecords(false);
      } finally {
        this.isUserDeleting = false;
      }
    },

    /**
     * Edit user
     * @emit edit-user
     */
    editUser(item) {
      this.$emit("edit-user", { ...item });
    },

    /**
     * accessDeniedNotification
     */
    accessDeniedNotification() {
      if (!this.isAdmin) {
        this.setSnackbar({
          value: true,
          message: this.$dictionary.app.users.accessDenied,
          type: this.$appConfig.snackbar.snackbarTypes.error,
        });
      }
    },
  },
  /**
    |--------------------------------------------------
    | Created lifecycle hook
    |--------------------------------------------------
    */
  created() {
    const { sort_by } = this.tableSettings;
    this.pagination.sortBy.push(sort_by ?? "email");
    this.pagination.sortDesc.push(this.isSortDesc);
  },
};
</script>
