<template>
  <div id="seeds__table">
    <!------------------------- Table  starts------------------------->
    <v-data-table
      :items="seeds"
      :headers="headers"
      :loading="isLoading"
      class="body-2"
      :items-per-page="paginationMeta.itemsPerPage"
      :loading-text="$dictionary.app.common.seeds.loading"
      :no-data-text="$dictionary.app.seeds.index.noResults"
      :sort-desc="tableSettings.sort_order === 'desc'"
      :sort-by="tableSettings.sort_by"
      @update:options="updateTableSettings"
      @update:items-per-page="updateItemsPerPage"
      @current-items="currentItems"
      :footer-props="{
        itemsPerPageOptions: tableSettings.itemsPerPageOptions,
        itemsPerPage: tableSettings.itemsPerPage,
      }"
    >
      <template #item.seed_address="{ item }">
        <table-matrics-row :text="item.seed_address" />
      </template>
      <template #item.status="{ item }">
        <span class="black--text">
          <v-switch
            v-model="item.status"
            dense
            hide-details
            class="mt-n1 status-switch-btn"
          ></v-switch>
        </span>
      </template>
      <template v-slot:[`item.actions`]>
        <v-menu dense offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on">
              <v-icon v-bind="attrs">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item>
              <v-icon class="mr-3 headline"> mdi-pencil </v-icon>
              <v-list-item-title class="edit-seed body-2 text-black--80 pr-3">
                Edit Seed
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-icon class="mr-3 headline"> mdi-delete </v-icon>
              <v-list-item-title class="delete-seed body-2 text-primary pr-3">
                Delete Seed
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <!------------------------- Table ends ------------------------->
  </div>
</template>

<script>
import { TableMatricsRow } from "../shared";
import { SORTING_ORDERS } from "@/constants";

export default {
  name: "SeedsTable",
  /**
   * ---------- Components ------------
   */
  components: {
    TableMatricsRow,
  },
  props: {
    /**
     *  ---------------- Props ------------------
     */
    seeds: {
      type: Array,
      required: true,
    },
    tableSettings: {
      type: Object,
      required: true,
    },
    updateSettings: {
      type: Function,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  /**
   * ---------------- Custom events ------------------
   */
  emits: ["get-seeds", "sorted-records"],
  /**
   * ---------------- Data properties ------------------
   */
  data() {
    return {
      pagination: { ...this.tableSettings, sortDesc: [true] },
    };
  },
  /**
   * ---------------- Watching properties ------------------
   */
  watch: {
    /**
     * Fetches seeds on the basis of page change
     * @emits get-seeds event in the parent component
     */
    pagination: {
      async handler(newVal, oldVal) {
        if (
          newVal.page !== oldVal.page ||
          newVal.itemsPerPage !== oldVal.itemsPerPage
        ) {
          this.$emit("get-seeds", this.params);
        }
      },
      deep: true,
    },
  },
  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    /**
     * Seeds listing pagination meta
     * @type {Object}
     */
    paginationMeta() {
      return {
        itemsPerPage: this.pagination.itemsPerPage || 10,
        serverItemsLength:
          this.pagination.page * this.seeds?.length +
          this.pagination.itemsPerPage,
      };
    },

    /**
     * Request params to be sent to the seeds api
     * to fetch the seeds  on the basis of the params
     */
    params() {
      return {
        page: this.pagination.page,
        per_page:
          this.pagination.itemsPerPage || this.paginationMeta.itemsPerPage,
      };
    },
  },
  /**
   * ---------------- Methods ------------------
   */
  methods: {
    /**
     * Emits a custom event with sorted items
     * @param {Array} items Sorted items
     */
    currentItems(items) {
      this.$emit("sorted-records", [...items]);
    },
    /**
     * Update sort order and sort by value in store
     */
    updateTableSettings(options) {
      let [sort_by] = options.sortBy;
      let [sort_order] = options.sortDesc;

      let table = {
        sort_order: sort_order ? SORTING_ORDERS.desc : SORTING_ORDERS.asc,
        sort_by: sort_by ?? "first_seen",
      };

      this.updateSettings(table);
    },

    /**
     * Update itemsPerPage value
     */
    updateItemsPerPage(val) {
      this.updateSettings({ itemsPerPage: val });
    },
  },
};
</script>
