<template>
  <div id="mailbox-providers__table">
    <!------------------------- Table  starts------------------------->
    <v-data-table
      :items="mailboxProviders"
      :headers="headers"
      :loading="isLoading"
      :options.sync="pagination"
      class="elevation-1 body-2"
      :items-per-page="settings.itemsPerPage"
      :no-data-text="$dictionary.app.mailboxProviders.index.noResults"
      :sort-desc="settings.sort_order === 'desc'"
      :sort-by="settings.sort_by"
      @update:options="updateTableSettings"
      @update:items-per-page="updateItemsPerPage"
      @current-items="currentItems"
      :footer-props="{
        itemsPerPageOptions: settings.itemsPerPageOptions,
        itemsPerPage: settings.itemsPerPage,
      }"
    >
      <template #item.mailbox_provider="{ item }">
        <span
          @click="emailSeedsNavigator(item.seed_domain)"
          class="text--primary cursor-pointer body-2"
        >
          {{ item.mailbox_provider }}
        </span>
      </template>
      <template #item.received_at="{ item }">
        <table-matrics-row :text="item.received_at" />
      </template>
      <template #item.inbox="{ item }">
        <table-matrics-row
          :count="item.inbox_count"
          :percent="item.inbox"
          :threshold-type="$appConfig.thresholds.inbox"
        />
      </template>
      <template #item.spam="{ item }">
        <table-matrics-row
          :count="item.spam_count"
          :percent="item.spam"
          :threshold-type="$appConfig.thresholds.spam"
        />
      </template>
      <template #item.missing="{ item }">
        <table-matrics-row
          :count="item.missing_count"
          :percent="item.missing"
          :threshold-type="$appConfig.thresholds.missing"
        />
      </template>
      <template #item.first_seen_date="{ item }">
        <table-matrics-row :text="item.first_seen" />
      </template>
      <template #item.first_seen="{ item }">
        <table-matrics-row :text="item.first_seen" />
      </template>
      <template #item.duration="{ item }">
        <table-matrics-row :text="item.duration" />
      </template>
    </v-data-table>
    <!------------------------- Table ends ------------------------->
  </div>
</template>

<script>
import { TableMatricsRow } from "../shared";
import { mapGetters, mapActions } from "vuex";
import { SORTING_ORDERS } from "@/constants";

/**
 * Campaigns mailbox provider table
 */
export default {
  name: "MailboxProvidersTable",
  /**
   *  ---------------- Components ------------------
   */
  components: {
    TableMatricsRow,
  },
  props: {
    /**
     *  ---------------- Props ------------------
     */
    mailboxProviders: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  /**
   * ---------------- Custom events ------------------
   */

  emits: ["navigate-to-Seeds", "sorted-records"],
  /**
   * ---------------- Data properties ------------------
   */
  data() {
    return {
      pagination: { sortDesc: [false] },
    };
  },
  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    ...mapGetters({
      settings: "settings/mailboxProvider",
    }),
    /**
     * Mailbox provider listing pagination meta
     * @type {Object}
     */
    paginationMeta() {
      return {
        itemsPerPage: this.pagination.itemsPerPage || 10,
        serverItemsLength:
          this.pagination.page * this.mailboxProviders?.length +
          this.pagination.itemsPerPage,
      };
    },

    /**
     * Request params to be sent to the account campaigns api
     * to fetch the Mailbox rpoviders of an account on the basis of the params
     */
    params() {
      return {
        page: this.pagination.page,
        per_page:
          this.pagination.itemsPerPage || this.paginationMeta.itemsPerPage,
      };
    },
  },
  /**
   * ---------------- Methods ------------------
   */
  methods: {
    ...mapActions({
      updateSettings: "settings/setMailboxProviderSettings",
    }),
    /**
     * emailSeedsNavigator
     * @emits navigate-to-Seeds event in the parent component to navigate to email addreess seeds page
     */
    emailSeedsNavigator(seedDomain) {
      if (!seedDomain) return;
      this.$emit("navigate-to-Seeds", seedDomain);
    },
    /**
     * Emits a custom event with sorted items
     * @param {Array} items Sorted items
     */
    currentItems(items) {
      this.$emit("sorted-records", [...items]);
    },
    updateTableSettings(options) {
      let [sort_by] = options.sortBy;
      let [sort_order] = options.sortDesc;

      let table = {
        sort_order: sort_order ? SORTING_ORDERS.desc : SORTING_ORDERS.asc,
        sort_by: sort_by ?? "mailbox_provider",
      };
      this.updateSettings(table);
    },
    /**
     * Updates items per page porperty
     */
    updateItemsPerPage(val) {
      this.updateSettings({ itemsPerPage: val });
    },
  },
};
</script>
