var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"email-seeds__table"}},[_c('v-data-table',{staticClass:"elevation-1 body-2",attrs:{"items":_vm.emailSeeds,"headers":_vm.headers,"loading":_vm.isLoading,"item-class":_vm.rowClass,"disable-pagination":"","hide-default-footer":"","loading-text":_vm.$dictionary.app.common.seeds.loading,"no-data-text":_vm.$dictionary.app.seeds.index.noResults,"sort-desc":_vm.isSortDesc,"sort-by":_vm.settings.sort_by},on:{"current-items":_vm.onCurrentItems,"update:sortDesc":function($event){_vm.isSortDesc=$event},"update:sort-desc":function($event){_vm.isSortDesc=$event},"update:options":_vm.updateTableSettings,"click:row":_vm.openDrawer},scopedSlots:_vm._u([{key:"item.seed_address",fn:function(ref){
var item = ref.item;
return [_c('table-matrics-row',{attrs:{"text":item.seed_address}})]}},{key:"item.received_at",fn:function(ref){
var item = ref.item;
return [_c('table-matrics-row',{attrs:{"text":item.received_at}})]}},{key:"item.sending_ip",fn:function(ref){
var item = ref.item;
return [_c('table-matrics-row',{attrs:{"text":item.sending_ip}})]}},{key:"item.mailbox_type",fn:function(ref){
var item = ref.item;
return [_c('table-matrics-row',{attrs:{"text":item.mailbox_type}})]}},{key:"item.mailbox_label",fn:function(ref){
var item = ref.item;
return [_c('table-matrics-row',{attrs:{"text":item.mailbox_label}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }