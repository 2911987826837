var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"mailbox-providers__table"}},[_c('v-data-table',{staticClass:"elevation-1 body-2",attrs:{"items":_vm.mailboxProviders,"headers":_vm.headers,"loading":_vm.isLoading,"options":_vm.pagination,"items-per-page":_vm.settings.itemsPerPage,"no-data-text":_vm.$dictionary.app.mailboxProviders.index.noResults,"sort-desc":_vm.settings.sort_order === 'desc',"sort-by":_vm.settings.sort_by,"footer-props":{
      itemsPerPageOptions: _vm.settings.itemsPerPageOptions,
      itemsPerPage: _vm.settings.itemsPerPage,
    }},on:{"update:options":[function($event){_vm.pagination=$event},_vm.updateTableSettings],"update:items-per-page":_vm.updateItemsPerPage,"current-items":_vm.currentItems},scopedSlots:_vm._u([{key:"item.mailbox_provider",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"text--primary cursor-pointer body-2",on:{"click":function($event){return _vm.emailSeedsNavigator(item.seed_domain)}}},[_vm._v(" "+_vm._s(item.mailbox_provider)+" ")])]}},{key:"item.received_at",fn:function(ref){
    var item = ref.item;
return [_c('table-matrics-row',{attrs:{"text":item.received_at}})]}},{key:"item.inbox",fn:function(ref){
    var item = ref.item;
return [_c('table-matrics-row',{attrs:{"count":item.inbox_count,"percent":item.inbox,"threshold-type":_vm.$appConfig.thresholds.inbox}})]}},{key:"item.spam",fn:function(ref){
    var item = ref.item;
return [_c('table-matrics-row',{attrs:{"count":item.spam_count,"percent":item.spam,"threshold-type":_vm.$appConfig.thresholds.spam}})]}},{key:"item.missing",fn:function(ref){
    var item = ref.item;
return [_c('table-matrics-row',{attrs:{"count":item.missing_count,"percent":item.missing,"threshold-type":_vm.$appConfig.thresholds.missing}})]}},{key:"item.first_seen_date",fn:function(ref){
    var item = ref.item;
return [_c('table-matrics-row',{attrs:{"text":item.first_seen}})]}},{key:"item.first_seen",fn:function(ref){
    var item = ref.item;
return [_c('table-matrics-row',{attrs:{"text":item.first_seen}})]}},{key:"item.duration",fn:function(ref){
    var item = ref.item;
return [_c('table-matrics-row',{attrs:{"text":item.duration}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }