var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"seeds__table"}},[_c('v-data-table',{staticClass:"body-2",attrs:{"items":_vm.seeds,"headers":_vm.headers,"loading":_vm.isLoading,"items-per-page":_vm.paginationMeta.itemsPerPage,"loading-text":_vm.$dictionary.app.common.seeds.loading,"no-data-text":_vm.$dictionary.app.seeds.index.noResults,"sort-desc":_vm.tableSettings.sort_order === 'desc',"sort-by":_vm.tableSettings.sort_by,"footer-props":{
      itemsPerPageOptions: _vm.tableSettings.itemsPerPageOptions,
      itemsPerPage: _vm.tableSettings.itemsPerPage,
    }},on:{"update:options":_vm.updateTableSettings,"update:items-per-page":_vm.updateItemsPerPage,"current-items":_vm.currentItems},scopedSlots:_vm._u([{key:"item.seed_address",fn:function(ref){
    var item = ref.item;
return [_c('table-matrics-row',{attrs:{"text":item.seed_address}})]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"black--text"},[_c('v-switch',{staticClass:"mt-n1 status-switch-btn",attrs:{"dense":"","hide-details":""},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})],1)]}},{key:"item.actions",fn:function(){return [_c('v-menu',{attrs:{"dense":"","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',_vm._b({},'v-icon',attrs,false),[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-icon',{staticClass:"mr-3 headline"},[_vm._v(" mdi-pencil ")]),_c('v-list-item-title',{staticClass:"edit-seed body-2 text-black--80 pr-3"},[_vm._v(" Edit Seed ")])],1),_c('v-list-item',[_c('v-icon',{staticClass:"mr-3 headline"},[_vm._v(" mdi-delete ")]),_c('v-list-item-title',{staticClass:"delete-seed body-2 text-primary pr-3"},[_vm._v(" Delete Seed ")])],1)],1)],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }